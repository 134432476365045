import { Component, OnInit } from '@angular/core';
import { SharedApiService } from '../../pages/shared/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string = '';
  constructor(private fb: FormBuilder, private sharedApi: SharedApiService, private router: Router) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {

  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;

      this.sharedApi.onlogin(username, password).subscribe((result) => {
        if (result.success && result.data) {
          this.sharedApi.saveData('userDetail', result.data);
          this.router.navigate(['/pages/lodge/dashboard']);
        }
        else {
          this.errorMessage = 'Login failed. Please check your credentials.';
        }

      },
        error => {
          this.errorMessage = 'Error occured';
          console.log(error);
          
        });

    }

  }

}
