import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { CREATE } from '../shared/constant';
import { BehaviorSubject, Observable, Subject, } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedApiService {
  private customerDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.customerDetails.asObservable();
  private _selectedRoom: Subject<any> = new Subject<any>();
  public tenant: any = "";
  positions = NbGlobalPhysicalPosition;

  // Common APIs

  baseUrl = "https://api.pos.lodge.hotelierpune.in/";

  constructor(private http: HttpClient, private toastrService: NbToastrService) {
    //this.baseUrl = "https://localhost:44342/";
  }

  public saveData(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
    this.tenant = value.tenantName
  }

  public getData(key: string) {
    var data: any = sessionStorage.getItem(key);
    if (data) {
      this.tenant = data.tenantName
      return JSON.parse(data);
    }
    else
      return null;
  }

  public removeData(key: string) {
    sessionStorage.removeItem(key);
  }

  public clearData() {
    sessionStorage.clear();
  }

  setSelectedRoomData(data: any): void {
    this._selectedRoom = data;
  }

  getSeletedRoomData() {
    return this._selectedRoom;
  }

  showToastMessage(status, message) {
    let position = this.positions.TOP_RIGHT;
    let duration = 5000;
    this.toastrService.show(message, status, { position, status, duration });
  }

  setTenant() {
    let data: any = sessionStorage.getItem('userDetail');
    if (data) {
      data = JSON.parse(data);
      this.tenant = data.tenantName;
    }
  }

  //LoadCustomerDetail Api
  public LoadCustomerDetail(custId: number): Observable<any> {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/CustomerDetail/GetById?id=" + custId;

    return this.http.get(url, httpOptions);
  }

  //Api delete customer Detail
  public onDelete(custId: number): Observable<any> {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + "api/CustomerDetail/DeleteById?id=" + custId;

    return this.http.delete(url, httpOptions);
  }

  public getCityName() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/Master/GetCityName";

    var data = this.http.get(url, httpOptions);

    return data;
  }

  public searchCityName(name: any) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + "api/Master/SearchCityName?name=" + name;
    return this.http.get(url, httpOptions);
  }

  public getCustomerTypeName() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/Master/GetCustomerType";

    var data = this.http.get(url, httpOptions);

    return data;
  }

  public getGenderName() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/Lodge/Master/GetAllGenders";

    var data = this.http.get(url, httpOptions);

    return data;
  }

  public getIdentityCardTypeName() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/Master/GetIdentityCardType";

    var data = this.http.get(url, httpOptions);

    return data;
  }

  public createUpdateCustomerDetail(action, customerDetailRequest: any) {
    this.setTenant();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'tenant': this.tenant
    });
    let options = { headers: headers };
    let url = "";

    if (action == CREATE)
      url = this.baseUrl + "api/CustomerDetail/Create";
    else
      url = this.baseUrl + "api/CustomerDetail/Update";

    return this.http.post<any>(url, customerDetailRequest, options);
  }

  //Employee Detail API
  public createUpdateEmployeeDetail(action, employeeDetailRequest: any) {
    this.setTenant();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'tenant': this.tenant
    });
    let options = { headers: headers };
    let url = "";

    if (action == CREATE)
      url = this.baseUrl + "api/Employee/Create";
    else
      url = this.baseUrl + "api/Employee/Update";

    return this.http.post<any>(url, employeeDetailRequest, options);
  }

  public getAllEmployee() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + 'api/Employee/GetAll';
    return this.http.get(url, httpOptions);
  }

  public editEmployee(id: number) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + 'api/Employee/Update' + id;
    return this.http.delete(url, httpOptions);
  }

  public deleteEmployee(id: number) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + 'api/Employee/DeleteById?id=' + id;
    return this.http.delete(url, httpOptions);
  }

  public getAllCustomerDetail() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + "api/CustomerDetail/GetAll";
    return this.http.get(url, httpOptions);
  }

  public getCustomerDetailById(custId: number) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + "api/CustomerDetail/GetById?Id=" + custId;
    return this.http.get(url, httpOptions);
  }

  public deleteCustomerDetail(custId) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + "api/CustomerDetail/DeleteById?id=" + custId;
    return this.http.delete(url, httpOptions);
  }

  public getCustomerByNameMobile(searchBy, value) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/CustomerDetail/GetByNameMobile?searchBy=" + searchBy + "&value=" + value;
    return this.http.get(url, httpOptions);
  }

  public getDesignationName() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/Master/GetDesignation";

    var data = this.http.get(url, httpOptions);

    return data;
  }

  public getDepartmentName() {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/Master/GetDepartment";

    var data = this.http.get(url, httpOptions);

    return data;
  }

  public getEmployeeDetailById(id: number) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };
    let url = this.baseUrl + "api/Employee/GetById?Id=" + id;
    return this.http.get(url, httpOptions);
  }

  public getCustomerByTypeValue(typeValue, value) {
    this.setTenant();
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': this.tenant
      }),
    };

    let url = this.baseUrl + "api/CustomerDetail/GetByCustTypeValue?typeValue=" + typeValue + "&value=" + value;
    return this.http.get(url, httpOptions);
  }

  // Register User API
  registerUser(emailId: string): Observable<any> {
    const params = { emailId }; // API expects an emailId parameter
    return this.http.get<any>(this.baseUrl + "api/Auth/UserRegistrationOtp", { params });
  }

  //login API

  onlogin(username: string, password: string): Observable<any> {
    const url = `${this.baseUrl + "Auth/Login"}?userName=${username}&password=${password}`;
    return this.http.get(url);
  }

}