<div class="background-div">

    <div>
        <img height="120px" width="350px" src="../../../assets/images/hotelierlogo1.png" alt="Logo"
            class="login-logo" />
    </div>


    <div class="centered-container">
        <table class="centered-table content">

            <tr>
                <td style="text-align: center;height: 100px; font-size: 24px; font-weight: 700; color: brown;">
                    Welcome !
                </td>
            </tr>

            <tr>

                <td>
                    <div
                        style="width: 100%; text-align: center; font-weight: 700; font-size: 20px; padding-bottom: 15px;color: black;">
                        Login
                    </div>

                    <div class="row justify-content-center">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                            <table>
                                <tr>
                                    <td style="font-weight: 700;color: black;">
                                        Username <span style="color: red;">*</span>
                                    </td>
                                    <td>
                                        <input autocomplete="off" class="input-box " type="text"
                                            formControlName="username" placeholder="Enter username" required />
                                    </td>

                                </tr>
                                <tr>
                                    <td style="font-weight: 700;color: black;">
                                        Password <span style="color: red;">*</span>
                                    </td>
                                    <td>
                                        <input autocomplete="off" class="input-box " type="password"
                                            formControlName="password" placeholder="Enter password" required />
                                    </td>

                                </tr>
                                <tr>
                                    <td>

                                    </td>
                                    <td>
                                        <button type="submit" class="btn btn-primary my-4">Sign in</button>

                                    </td>

                                </tr>
                                <tr>
                                    <td colspan="2" style="color: red;">
                                        {{errorMessage}}
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>

                </td>

            </tr>

        </table>
    </div>

    <table
        style="font-size: 16px; width: 100%;position: absolute; bottom: 0;margin-bottom: 10px; color: yellow; font-weight: 700;">
        <tr>
            <td>
                &nbsp;&nbsp; Hotelier Software, Pune
            </td>
            <td style="text-align: right;">
                Contact Us : +91 92721 60345 / 90223 77640 &nbsp;&nbsp;
            </td>
        </tr>
    </table>

</div>