<div class="header-container no-printme">
  <div class="logo-container">
    <a href="#" class="sidebar-toggle" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
      (click)="toggleSidebar()">
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <a style="font-size: 30px;" *ngIf="userDetail" class="logo" href="#" (click)="navigateHome()">
      {{userDetail.shopName}}
    </a>
  </div>

  <!-- <nb-select
    status="primary"
    matRipple
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
  >
    <nb-option
      *ngFor="let theme of themes"
      [value]="theme.value"
      matRipple
    >{{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container no-printme">
  <nb-actions size="small">

    <!--<nb-action class="control-item">
      <nb-search
        type="rotate-layout"
        matRipple
        [matRippleUnbounded]="true"
        [matRippleCentered]="true"
      ></nb-search></nb-action>-->
    <nb-action class="control-item" icon="email-outline" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true"></nb-action>

    <nb-action class="control-item" icon="bell-outline" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true"></nb-action>

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true">
      <nb-user nbContextMenuTag="my-context-menu" [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly"
        [name]="userDetail?.userName" [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>


</div>